import React from "react";
import ReactPlayer from "react-player/vimeo";

import Layout from "../components/layout";
import SEO from "../components/seo";

import styles from "./about.module.css";

const Holidays = props => {
  return (
    <Layout>
      <div className={styles.page}>
        <div className={styles.pagecontentholidays}>
          <SEO title="Holidays" />
          <div className={styles.playerWrapper}>
            <ReactPlayer
              url="https://vimeo.com/782981184"
              controls={true}
              className={styles.reactPlayer}
              width="100%"
              height="100%"
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Holidays;
